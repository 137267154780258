@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import "~antd/lib/style";
@import "~antd/lib/result/style";
@import "~antd/lib/spin/style";
@import "~antd/lib/empty/style";
@import "~antd/lib/button/style";
@import "~antd/lib/typography/style";
@import "~antd/lib/layout/style";
@import "~antd/lib/menu/style";
@import "~antd/lib/space/style";
@import "~antd/lib/card/style";

#webbot,
.ant-spin-nested-loading,
.ant-spin-container,
.ant-layout {
  height: 100%;
}

.kios-professional-orientation-widget {
  font-family: "Nunito", sans-serif;
  &__loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: sticky;
    & .ant-spin-text {
      margin-top: 8px;
    }
  }

  &-result {
    &__title {
      text-transform: initial !important;
      @media only screen and (max-width: 769px) {
        font-size: 30px !important;
      }
    }
  }

  &__profession {
    background: #fff;
    padding: 20px;
    border: 1px solid #eee;
    border-radius: 10px;
    margin-top: 20px;
    &-buttons {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      & a {
        margin: 0 !important;
      }
      & > *:first-child {
        margin-right: 15px !important;
      }
    }
    &-title {
      margin: 0 !important;
    }
    &-description {
      margin: 0 !important;
      margin-top: 10px !important;
    }
    &-link {
      margin-top: 10px !important;
      display: table;
    }
    &-button {
      margin-top: 15px;
    }
  }
}

.activity-container {
  display: block !important;
}

// !NEW
.ant-layout {
  height: max-content !important;
}

.kios-professional-widget {
  min-height: 100vh !important;
  background: #fff;
}

.kios-professional-loading-spinner {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kios-professional-sidebar {
  background: #c4c4c4;
  min-width: 20% !important;
  min-height: 100%;
}

.kios-professional-orientation-widget-result {
  padding: 2rem 1rem 0;
}

.kios-professional-orientation-widget__summary {
  padding: 0 0 2rem;
}

.kios-professional-sidebar-professions-tabs {
  list-style-type: none;
  background: #c4c4c4;

  .ant-spin-nested-loading {
    min-width: 100%;
  }
  .ant-space {
    min-width: 100%;
  }

  .ant-btn {
    min-width: 100%;
    height: 3.5rem;
  }
}

.kios-professional-orientation-widget-profession {
  background: #fff;
}

.kios-professional-orientation-widget__professions {
  padding: 1.5rem 3rem;
  background: #fff;
}

.kios-professional-sidebar-professions-button {
  padding-bottom: 0;
  border-color: #9d9d9d;
  background: #9d9d9d;
}

.kios-professional-profession-content {
  padding: 2rem 3rem;
  background: #fff;
}

.kios-professional-profession-unselected {
  min-width: 80vw;
  min-height: 100vh;
  justify-content: center;
  align-items: center;

  .ant-typography {
    display: flex;
    padding-top: 0.5rem;
  }
}

.kios-professional-profession-description-paragraph {
  padding-right: 3rem;
}

.kios-professional-profession-description {
  justify-content: space-between;
  flex-direction: row;
}

.kios-professional-profession-description-summary {
  color: #a3a3a3;
  padding: 0 !important;
  margin-bottom: 0.5rem !important;
}

.kios-professional-profession-description-learn-more {
  text-decoration: underline !important;
}

.kios-professional-profession-description-button-group {
  min-width: 30%;
}

.kios-professional-profession-description-button-group-footer {
  flex-direction: row;
  justify-content: space-around;
  min-width: 40% !important;

  .kios-professional-profession-description-button-group-button {
    padding: 0.5rem 3rem;
    min-width: 100% !important;
    align-items: center;
  }

  .kios-professional-profession-description-button-group-space {
    min-width: 30%;
    flex-direction: row;

    .ant-space-item {
      min-width: 100%;
    }
  }
}

.kios-professional-profession-description-button-group-space {
  padding: 3rem 0 0;
}

.kios-professional-profession-description-button-group-button {
  display: flex;
  min-width: 100%;
  padding-bottom: 0;
  justify-content: center;
  align-items: center;
  line-height: 1.5rem;
}

.kios-professional-profession-podcasts-and-reviews-video-list {
  display: flex;
  flex-direction: row;
}

.kios-professional-profession-podcasts-and-reviews-video-list-item {
  min-height: 8rem;
  min-width: 15rem;
  background: #c4c4c4;
  cursor: pointer;
}

.kios-professional-profession-required-skills {
  padding: 2rem 0 0;
  flex-direction: column !important;
}

.kios-professional-profession-required-skills-list {
  color: #a3a3a3;
}
.kios-professional-demand-charts-wrapper {
  flex-direction: column !important;
  .kios-professional-demand-charts {
    padding: 2rem 0 0;
    justify-content: space-between;

    .kios-professional-demand-chart {
      min-width: 45%;
    }
  }
  .kios-professional-profession-description-button-wrapper {
    justify-content: center;
    .kios-professional-profession-description-button {
      display: flex;
      padding-bottom: 0;
      justify-content: center;
      align-items: center;
      line-height: 1.5rem;

      padding: 0.5rem 3rem;
      min-width: 30% !important;
      align-items: center;
    }
  }
}

.kios-professional-cart-wrapper {
  margin-top: 2rem !important;
}
